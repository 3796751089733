// Packages
import React from "react"

// Utils
import { allBankersData } from "@utils/mb"

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      {React.cloneElement(element, {
        ...props,
        leader: allBankersData.find(
          ({ slug }) => slug === props.pageContext.slug
        ),
      })}
    </>
  )
}
