// Packages
import { getPhoneNumbers } from "@abmg-components"

// Data
const bankersDataJSON = require("@data/banker-data.json")

// Functions
export const sortAlphabetically = (data, property, order) => {
  return data.sort((a, b) => {
    if (order.match(/asc/i)) {
      if (a[property] > b[property]) return 1
      if (a[property] === b[property]) return 0
      return -1
    } else if (order.match(/desc/i)) {
      if (b[property] > a[property]) return 1
      if (b[property] === a[property]) return 0
      return -1
    }
  })
}

export const sortByDate = (data, property, order) => {
  return data.sort((a, b) => {
    if (order.match(/asc/i)) {
      return a[property] - b[property]
    } else if (order.match(/desc/i)) {
      return b[property] - a[property]
    }
  })
}

export const getAllBankerData = bankerData => {
  let allBankerData
  if (bankerData) {
    allBankerData = {
      ...bankerData,
      mobile: bankerData.contactInformation.mobile,
      office: bankerData.contactInformation.office,
      extension: bankerData.contactInformation.extension,
      favoredPhone: bankerData.contactInformation.favoredPhone,
      phone: getPhoneNumbers(bankerData.contactInformation)?.primaryPhone,
      phoneText: getPhoneNumbers(bankerData.contactInformation)
        ?.primaryPhoneText,
      altPhone: getPhoneNumbers(bankerData.contactInformation)?.altPhone,
      altPhoneText: getPhoneNumbers(bankerData.contactInformation)
        ?.altPhoneText,
      category: bankerData.category,
      businessCardTheme: bankerData.businessCardTheme || "default",
      firstName: bankerData.about?.firstName,
      lastName: bankerData.about?.lastName,
      fullName: bankerData.about?.fullName,
      name: bankerData.about?.fullName,
      jobTitle: bankerData.about?.jobTitle,
      email:
        bankerData.contactInformation.teamEmail ||
        bankerData.contactInformation.email,
      calendly: bankerData.contactInformation.calendly,
      nmls: bankerData.nmlsInfo?.nmls,
      licenses: bankerData.nmlsInfo?.licenses,
      stateSpecificLicense: bankerData.contactInformation.stateSpecificLicense,
      city: bankerData.address?.city,
      state: bankerData.address?.state,
      street: bankerData.address?.street,
      suite: bankerData.address?.suite,
      zip: bankerData.address?.zip,
      facebook: bankerData.socials?.facebook,
      linkedin: bankerData.socials?.linkedin,
      zillow: bankerData.socials?.zillow,
      instagram: bankerData.socials?.instagram,
      headshot: bankerData.hero?.headshot,
    }
  }
  return allBankerData
}

// Variables
export const allBankersData = bankersDataJSON.map(bankerData => {
  return getAllBankerData(bankerData)
})
